import React, { useContext, useEffect, useState } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import axios from "../api/axios/axios";
import { CLIENTID, FLOWID } from "../constants/Configuration";
import { ProfileContext } from "../routes/PrivateRoute";

// import seq from "seq-logging";
let seq = require("seq-logging/browser");

const seqLogger = ({ metaData, userName, stage }) => {
  let logger = new seq.Logger({ serverUrl: "https://prod-logs.upesi.co.ke" });

  logger.emit({
    messageTemplate: JSON.stringify({
      stage,
      userName,
      metaData,
    }),
    timestamp: new Date(),
    level: stage,
    properties: {
      ApplicationName: "WebApp",
      metaData: metaData,
      userName: userName,
      stage: stage,
    },
  });

  logger.close();
};

export default function Kyc() {
  const navigate = useNavigate();
  const [isLoading] = useState(true);
  const [message, setMessage] = useState("Getting profile details...");
  const { kycDetails } = useContext(ProfileContext);

  const processMetaKYC = () => {
    seqLogger({
      metaData: kycDetails?.userID,
      userName: kycDetails?.email,
      stage: "Kyc Started",
    });

    document.getElementById("mati_button").click();
    const button = document.getElementById("mati_button");

    button.addEventListener("mati:loaded", () => {});

    button.addEventListener("mati:userStartedSdk", (event) => {});

    button.addEventListener("mati:userFinishedSdk", (event) => {
      let interval = setInterval(() => {
        axios
          .get(
            `/api/v1/kyc/getkycstatus?verificationId=${event.detail.verificationId}`
          )
          .then((res) => {
            if (res.data.status !== "Pending") {
              clearInterval(interval);
              navigate({
                pathname: `/kyc-success`,
                search: createSearchParams({
                  sessionId: event.detail.verificationId,
                }).toString(),
              });
            } else {
              setMessage("Still Verifying KYC");
            }
          });
      }, 5000);
    });

    // Call to receive message of flow not finished
    button.addEventListener("mati:exitedSdk", (event) => {
      navigate(`/kyc-fail`);
    });
  };

  useEffect(() => {
    if (kycDetails?.userID) processMetaKYC();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kycDetails]);

  if (isLoading) {
    return (
      <FullPageLoader
        message={message}
        userId={kycDetails?.userID}
        // primaryInfo={primaryInfo}
      />
    );
  } else {
    return (
      <>
        <div
          style={{
            padding: "5rem 0 0 0",
          }}
        >
          {message}
        </div>
      </>
    );
  }
}

const FullPageLoader = ({ message, userId }) => {
  const metaData = { userId, platform: "WebApp" };

  return (
    <>
      <div
        style={{
          padding: "5rem 0 0 0",
        }}
      >
        {message}
      </div>
      <mati-button
        style={{ display: "none" }}
        id="mati_button"
        clientid={CLIENTID}
        flowId={FLOWID}
        metadata={JSON.stringify(metaData)}
      />
      <div
        className="loader-container"
        style={{
          display: "flex",
          height: "80vh",
          width: "100%",
          alignItems: "center",
        }}
      > 
        <div className="loader">
          <div className="loader-inner">
            <div className="loader-inner-circle"></div>
          </div>
        </div>
      </div>
    </>
  );
};
