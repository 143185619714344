import classNames from "classnames";
import { ErrorMessage, Form, Formik } from "formik";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { registerUser } from "../../../api/registerUser";
import { FormContext } from "../Registration";

const ValidationSchema = Yup.object().shape({
  referralSource: Yup.string().required("Please select how you heard about us"),
});

const referralOptions = [
  { id: "Friend/Family/Colleague", label: "Friend/Family/Colleague" },
  { id: "Social Media", label: "Social Media" },
  { id: "Search Engine", label: "Search Engine" },
  { id: "Events", label: "Events" },
  { id: "Advertisement", label: "Advertisement" },
  { id: "Email or Newsletter", label: "Email or Newsletter" },
];

function Referral() {
  const navigate = useNavigate();
  const { formData, setFormData } = useContext(FormContext);
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = (values) => {
    const data = { ...formData, ...values };
    setFormData(data);
    registerUser({ formData: data, setIsLoading, navigate });
  };

  return (
    <Formik
      initialValues={{
        referralSource: formData.referralSource,
      }}
      validationSchema={ValidationSchema}
      onSubmit={(val) => onSubmit(val)}
    >
      {({ handleChange, handleBlur, handleSubmit, errors, touched, values }) => (
        <Form id="registration-form" className="row justify-content-center" onSubmit={handleSubmit}>
          <div className="h3 text-center step-title text-orang">
            Thank you for your registration. Just a quick one before you Go!
          </div>
          <h4 className="py-2">Kindly, where did you hear about us?</h4>
          <div>
            {referralOptions.map((option) => (
              <div key={option.id} className="form-group col-12 col-sm-8 col-lg-6 col-xl-4 mb-3 mx-auto">
                <label
                  className={`form-control form-input d-flex align-items-center ${
                    errors.referralSource && touched.referralSource ? "form-error" : ""
                  }`}
                  style={{
                    cursor: "pointer",
                    paddingLeft: "15px",
                    height: "auto",
                    display: "flex",
                  }}
                >
                  <div
                    style={{
                      width: "18px",
                      height: "18px",
                      borderRadius: "50%",
                      border: "2px solid #ced4da",
                      marginRight: "10px",
                      position: "relative",
                      display: "inline-flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <input
                      type="radio"
                      name="referralSource"
                      value={option.id}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      checked={values.referralSource === option.id}
                      style={{
                        opacity: "0",
                        position: "absolute",
                        width: "100%",
                        height: "100%",
                        margin: "0",
                        cursor: "pointer",
                      }}
                    />
                    {values.referralSource === option.id && (
                      <div
                        style={{
                          width: "10px",
                          height: "10px",
                          borderRadius: "50%",
                          backgroundColor: "#f28021",
                        }}
                      ></div>
                    )}
                  </div>
                  {option.label}
                </label>
              </div>
            ))}
          </div>

          <div className="error-text col-12 text-center mb-2">
            <ErrorMessage name="referralSource" />
          </div>

          <div className="col-12 col-sm-6 col-xl-4">
            <button
              id="reg-btn"
              type="submit"
              disabled={isLoading ? true : false}
              className={"btn btn-orange w-100 " + classNames({ spin: isLoading }, "load-button")}
            >
              Submit
              <span className="spinner"></span>
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default Referral;
