export const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export function isEmptyObject(obj) {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
}

//detect sequential values in a password
export const hasSequentialChars = (password) => {
  const lowerCasedPassword = password.toLowerCase();
  const length = lowerCasedPassword.length;

  // Common keyboard sequences
  const keyboardSequences = ["qwertyuiop", "asdfghjkl", "zxcvbnm", "1234567890"];

  // Check for ASCII sequential patterns (e.g., abc, 123)
  for (let i = 0; i < length - 3; i++) {
    const char1 = lowerCasedPassword.charCodeAt(i);
    const char2 = lowerCasedPassword.charCodeAt(i + 1);
    const char3 = lowerCasedPassword.charCodeAt(i + 2);
    const char4 = lowerCasedPassword.charCodeAt(i + 3);

    // Check for ascending or descending sequences in ASCII order
    if (
      (char1 + 1 === char2 && char2 + 1 === char3 && char3 + 1 === char4) || // Ascending
      (char1 - 1 === char2 && char2 - 1 === char3 && char3 - 1 === char4) // Descending
    ) {
      return true; // Password contains a sequential pattern
    }
  }

  // Check for keyboard patterns
  for (let pattern of keyboardSequences) {
    for (let i = 0; i < pattern.length - 3; i++) {
      const subPattern = pattern.substring(i, i + 4); // Check sequences of 4 characters
      if (lowerCasedPassword.includes(subPattern)) {
        return true; // Password contains a keyboard sequential pattern
      }
    }
  }

  return false;
};
