import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import useFetchAllCountriesData from "../../hooks/useFetchAllCountriesData";
import useFetchBanksBySwiftCode from "../../hooks/useFetchBanksBySwiftCode";
import useFetchBeneficiaryRelationships from "../../hooks/useFetchBeneficiaryRelationships";
import useFetchBridgeBankBranches from "../../hooks/useFetchBridgeBankBranches";
import useFetchBridgeBanks from "../../hooks/useFetchBridgeBanks";
import useFetchBridgeServiceProvider from "../../hooks/useFetchBridgeServiceProvider";
import useFetchCashPickupLocations from "../../hooks/useFetchCashPickupLocations";
import useFetchCountryDeliveryOptionFields from "../../hooks/useFetchCountryDeliveryOptionFields";
import useFetchReceiverDocumentTypes from "../../hooks/useFetchReceiverDocumentTypes";
import useFetchStates from "../../hooks/useFetchStates";
import useFetchSupportedBanksBranches from "../../hooks/useFetchSupportedBanksBranches";
import "./Beneficiary.css";
import Stepper from "./Stepper/Stepper";
import Step from "./Steps/Step";
import { clearStateOnCountryChange, countryDetails, supportsRelation, updateState } from "./beneficiaryUtil";
import { ProfileContext } from "../../routes/PrivateRoute";
import useFetchMobileProviders from "../../hooks/useFetchMobileProviders";

export const FormContext = createContext();

const Beneficiary = () => {
  const location = useLocation();
  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const [paymentService, setPaymentService] = useState("");
  const [beneficiaryData, setBeneficiaryData] = useState(); // TODO: check if I need this(from send money journey I think-to confirm)
  const [isLoading, setIsLoading] = useState(false);
  const [countrySupportsRelation, setCountrySupportsRelation] = useState(false);

  const [deliveryMethod, setDeliveryMethod] = useState({
    countryAlpha2Code: "",
    countryPhoneCode: "",
    countryName: "",
    countryID: "",
    deliveryOption: "",
    deliveryOptionId: "",
    beneficiaryType: "",
    relationshipID: 0,
    relationShip: "",
  });

  const [deliveryType, setDeliveryType] = useState({
    cashDetails: {
      mobileNumberCode: "",
      mobileNumber: "",
      cashPickupLocersation: "",
      locationId: 0,
    },
    bankDetails: {
      tempSwiftCode: "",
      swiftCode: "",
      bankName: "",
      bankCode: "",
      bankBranch: "",
      bankBranchCode: "",
      accountName: "",
      accountNumber: "",
      confirmAccountNumber: "",
      sortCode: "",
      confirmSortCode: "",
      bankMobileNumber: "",
      bankMobileCode: "",
      bankId: 0,
    },
    mobileDetails: {
      mobileId: 0,
      walletMobileCode: "",
      walletMobileNumber: "",
      walletId: "",
      hasMobileMoneyProvider: false, // check if mobileMoneyProvider ID si required
      mobileMoneyProviderID: 0,
      mobileMoneyProviderName: "",
    },
  });

  const [locationDetails, setLocationDetails] = useState({
    state: "",
    city: "",
    address: "",
    street: "",
    addressLine: "",
    zipCode: "",
  });

  const { kycDetails } = useContext(ProfileContext);

  const [personalInfo, setPersonalInfo] = useState({
    fullName: "",
    firstName: "",
    middleName: "",
    lastName: "",
    phoneNumberCode: "",
    phoneNumber: "",
    nickName: "",
    documentType: "",
    documentTypeID: "",
    idNumber: "",
    documentExpiresOn: "",
    beneficiaryId: 0,
    userId: kycDetails?.userID,
    email: "",

    businessDescription: "",
    businessRegistrationIssuedBy: "",
    typeOfBusiness: "",
    regionalBeneficiaryName: "",
  });
  const [singleCountryData, setSingleCountryData] = useState([]);

  const { countryName, countryID, beneficiaryType, deliveryOptionId } = deliveryMethod;
  const { swiftCode } = deliveryType.bankDetails;
  const { bankCode } = deliveryType.bankDetails;
  let body = {
    serviceTypeID: deliveryOptionId,
    toCountryID: countryID,
    paymentService,
  };

  const { countriesData } = useFetchAllCountriesData(
    "IncludeDeliveryMethods=true&IncludeBeneficiaryTypes=true&IncludeBeneficiaryDetails=true"
  );
  const { beneficiaryRelationships } = useFetchBeneficiaryRelationships();
  const { activeServiceProvider, loadingActiveServiceProvider } = useFetchBridgeServiceProvider(body);
  const { countryDeliveryOptionFields } = useFetchCountryDeliveryOptionFields(deliveryOptionId, countryID);
  const { bridgeBank } = useFetchBridgeBanks(countryID, paymentService);
  const { bridgeBankBranches } = useFetchBridgeBankBranches(countryID, bankCode, paymentService);
  const { banksBySwiftCode, setBanksBySwiftCode } = useFetchBanksBySwiftCode(countryID, swiftCode, setIsLoading);
  const { bankList, setBankList } = useFetchSupportedBanksBranches(swiftCode, setIsLoading);

  const { cashPickupLocations } = useFetchCashPickupLocations(countryID, deliveryOptionId);
  const { mobileProviders } = useFetchMobileProviders(countryID);
  const { states } = useFetchStates(countryID, activeServiceProvider);
  const { documentTypes, loadingdocumentTypes } = useFetchReceiverDocumentTypes({
    type: "Receiver",
    countryID,
    paymentService,
    customerType: beneficiaryType,
    serviceTypeID: deliveryOptionId,
  });

  const updateAllFormData = useCallback(updateState, []);

  // on country change clear data
  useEffect(() => {
    if (countryID && countryID !== location?.state?.countryID) {
      return;
    } else {
      updateAllFormData({
        location,
        setDeliveryType,
        setDeliveryMethod,
        setLocationDetails,
        setPersonalInfo,
        setBeneficiaryData,
        userId: kycDetails?.userID,
        mobileProviders,
      });
    }
  }, [updateAllFormData, location, kycDetails, countryID, mobileProviders]);

  useEffect(() => {
    if (documentTypes?.length > 0 && location?.state?.documentTypeID) {
      //get documentType
      const docType = documentTypes.filter((doc) => doc.id === location.state.documentTypeID)[0];
      //autofill  doc info
      setPersonalInfo((prevState) => ({
        ...prevState,
        documentTypeID: docType?.id,
        documentType: docType?.name,
      }));
    }
  }, [location, documentTypes]);

  useEffect(() => {
    if (countryID) {
      // if there is no data on location state clear form on country change
      if (!location?.state || countryID !== location.state.countryID) {
        clearStateOnCountryChange({
          deliveryMethod,
          setDeliveryMethod,
          deliveryType,
          setDeliveryType,
        });
        setBankList([]);

        setLocationDetails({
          state: "",
          city: "",
          address: "",
          street: "",
          addressLine: "",
          zipCode: "",
        });
      }

      setCountrySupportsRelation(supportsRelation(countryName));

      setSingleCountryData(countryDetails(countryID, countriesData));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryID, countriesData, countryName]);

  useEffect(() => {
    // countryName === "JAPAN" &&
    beneficiaryType === "Company" ? setPaymentService("C2B") : setPaymentService("C2C");
    return () => {};
  }, [countryName, beneficiaryType]);

  let formData = {
    deliveryMethod,
    setDeliveryMethod,
    deliveryType,
    setDeliveryType,
    locationDetails,
    setLocationDetails,
    personalInfo,
    setPersonalInfo,
  };

  let apiData = {
    beneficiaryRelationships,
    cashPickupLocations,
    states,
    documentTypes,
    countryDeliveryOptionFields,
    bridgeBank,
    bridgeBankBranches,
    banksBySwiftCode,
    setBanksBySwiftCode,
    bankList,
    singleCountryData,
    mobileProviders,
  };
  let values = {
    isLoading,
    activeStepIndex,
    setActiveStepIndex,
    paymentService,
    activeServiceProvider,
    loadingActiveServiceProvider,
    loadingdocumentTypes,
    formData,
    countriesData,
    singleCountryData,
    apiData,
    location,
    beneficiaryData,
    countrySupportsRelation,
    userId: kycDetails?.userID,
    // saveBeneficiaryDetails,
  };

  return (
    <FormContext.Provider value={values}>
      <div className="beneficiary d-flex flex-column align-items-center justify-content-start">
        <Stepper />
        <Step />
      </div>
    </FormContext.Provider>
  );
};

export default Beneficiary;
