import { useEffect, useState } from "react";
import axios from "../api/axios/axios";

export default function useFetchMobileProviders(countryId) {
  const [mobileProviders, setMobileProviders] = useState([]);

  useEffect(() => {
    let isMounted = true;

    /* UGANDA(78) GHANA(242) & NIGERIA(6) */
    const requiresMobileProviders = [78, 242, 6].includes(countryId);
    if (countryId && requiresMobileProviders) {
      (async function () {
        try {
          const response = await axios.get(`/api/Utility/GetMobileProviders/${countryId}`);
          if (isMounted) setMobileProviders(response.data);
        } catch (err) {
          if (isMounted) setMobileProviders([]);
        } finally {
        }
      })();
    }
    return () => (isMounted = false);
  }, [countryId]);

  return { mobileProviders };
}
