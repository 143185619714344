import React, { useContext, useState } from "react";

import { FormContext } from "../../../Beneficiary";
import MobileDetails from "./MobileDetails";

function MobileDetailsContainer() {
  const {
    activeStepIndex,
    setActiveStepIndex,
    formData,
    apiData: { mobileProviders },
  } = useContext(FormContext);

  // const [selectedMobileProvider, setSelectedMobileProvider] = useState(null);

  const handleMobileProvider = (val, setFieldValue) => {
    setFieldValue("mobileMoneyProviderName", val.name);
    setFieldValue("mobileMoneyProviderID", val.id);
    // setSelectedMobileProvider(val);
  };

  const onSubmit = (values) => {
    formData.setPersonalInfo({ ...formData.personalInfo, phoneNumber: values.walletMobileNumber });
    formData.setDeliveryType({ ...formData.deliveryType, mobileDetails: values });
    setActiveStepIndex(activeStepIndex + 1);
  };

  let props = { formData, onSubmit, mobileProviders, handleMobileProvider };
  return <MobileDetails props={props} />;
}

export default MobileDetailsContainer;
