export const relationSupportedCountries = [
  "AUSTRIA",
  "BELGIUM",
  "BULGARIA",
  "CYPRUS",
  "DENMARK",
  "ESTONIA",
  "FRANCE",
  "GERMANY",
  "ITALY",
  "LATVIA",
  "LITHUANIA",
  "LUXEMBOURG",
  "MALTA",
  "MONACO",
  "NETHERLANDS",
  "PORTUGAL",
  "SPAIN",
  "UNITED KINGDOM OF GREAT BRITAIN AND NORTHERN IRELAND",
];

//get single country data
export const countryDetails = (countryID, countriesData) => {
  let data = countriesData.filter((country) => country.id === countryID)[0];

  return data;
};

export const supportsRelation = (countryName) => {
  return relationSupportedCountries.includes(countryName);
};

export const clearStateOnCountryChange = ({ deliveryMethod, setDeliveryMethod, deliveryType, setDeliveryType }) => {
  setDeliveryMethod({
    ...deliveryMethod,
    deliveryOption: "",
    // deliveryOptionId: "",
    beneficiaryType: "",
  });
  setDeliveryType({
    ...deliveryType,
    bankDetails: {
      ...deliveryType.bankDetails,
      swiftCode: "",
      bankName: "",
      bankCode: "",
      bankBranch: "",
      bankBranchCode: "",
      accountName: "",
      accountNumber: "",
      confirmAccountNumber: "",
      sortCode: "",
      confirmSortCode: "",
      bankMobileNumber: "",
    },
    mobileDetails: {
      ...deliveryType.mobileDetails,
      mobileMoneyProviderID: 0,
      mobileMoneyProviderName: "",
    },
  });
};

// update state with data on location state
export const updateState = ({
  location,
  setDeliveryType,
  setDeliveryMethod,
  setLocationDetails,
  setPersonalInfo,
  setBeneficiaryData,
  mobileProviders,
}) => {
  // allow country to update when user want to change, send money journey
  if (location.state) {
    const {
      callingCode,
      countryName,
      serviceName,
      countryID,
      serviceId,
      beneficiaryType,
      relationShip,
      relationshipID,

      state,
      city,
      street,
      zipCode,
      address,
      addressLine,

      firstName,
      lastName,
      middleName,
      nickName,
      documentExpiresOn,
      idNumber,

      businessDescription,
      businessRegistrationIssuedBy,
      typeOfBusiness,
      regionalBeneficiaryName,
      email,
    } = location.state;
    let phoneCode = "+" + callingCode;

    // from send money journey
    if (location?.state?.action === "SEND_MONEY_NEW_BENEFICIARY") {
      setDeliveryMethod((prevState) => ({
        ...prevState,
        countryName,
        countryID,
        deliveryOption: serviceName,
        deliveryOptionId: serviceId,
      }));
      setDeliveryType((prevState) => ({
        ...prevState,
        cashDetails: {
          ...prevState.cashDetails,
          mobileNumberCode: phoneCode,
        },
        bankDetails: {
          ...prevState.bankDetails,
          bankMobileCode: phoneCode,
        },
        mobileDetails: {
          ...prevState.mobileDetails,
          walletMobileCode: phoneCode,
        },
      }));
      setPersonalInfo((prevState) => ({
        ...prevState,
        phoneNumberCode: phoneCode,
      }));
    } else {
      // from manage beneficiaries
      const {
        id,
        // bank
        accountName,
        bankBranch,
        bankBranchCode,
        bankCode,
        bankName,
        phoneNumber,
        mobileNumber,
        sortCode,
        swiftCode,
        accountNumber,

        // cash
        pickupLocation,

        //wallet
        eWalletId,
        mobileMoneyProviderID,
      } = location.state.serviceTypeDetails;

      // checks if it has a + symbol at the beginning
      let formattedPhoneCode = callingCode[0] === "+" ? callingCode.slice(1) : callingCode;
      let formattedPhoneNo = phoneNumber && phoneNumber[0] === "+" ? phoneNumber.slice(1) : phoneNumber;
      let formattedPhoneNo2 =
        location?.state?.phoneNumber[0] === "+" ? location?.state?.phoneNumber.slice(1) : location?.state?.phoneNumber;
      let formattedMobileNumber = mobileNumber && mobileNumber[0] === "+" ? mobileNumber.slice(1) : mobileNumber;

      let serviceTypePhoneNumber = formattedPhoneNo?.slice(formattedPhoneCode.length);
      let mobileServiceNumber = formattedMobileNumber?.slice(formattedPhoneCode.length);
      let phoneNo = formattedPhoneNo2.slice(formattedPhoneCode.length);

      if (serviceId === 1) {
        //bank details

        setDeliveryType((prevState) => ({
          ...prevState,
          bankDetails: {
            ...prevState.bankDetails,
            swiftCode,
            bankName,
            bankCode,
            bankBranch,
            bankBranchCode,
            accountName,
            accountNumber,
            confirmAccountNumber: accountNumber,
            sortCode,
            confirmSortCode: sortCode,
            bankMobileNumber: serviceTypePhoneNumber ? serviceTypePhoneNumber : phoneNo,
            bankMobileCode: phoneCode,
            bankId: id,
          },
        }));
      } else if (serviceId === 2) {
        //cash pickup
        setDeliveryType((prevState) => ({
          ...prevState,
          cashDetails: {
            ...prevState.cashDetails,
            mobileNumberCode: phoneCode,
            mobileNumber: phoneNo,
            cashPickupLocation: pickupLocation,
            locationId: id,
          },
        }));
      } else if (serviceId === 3) {
        //mobile wallet

        const mobileMoneyProviderName = mobileProviders.filter((provider) => provider.id === mobileMoneyProviderID)[0]
          ?.name;

        setDeliveryType((prevState) => ({
          ...prevState,
          mobileDetails: {
            ...prevState.mobileDetails,
            id,
            walletId: eWalletId,
            walletMobileCode: phoneCode,
            walletMobileNumber: mobileServiceNumber,
            hasMobileMoneyProvider: Number(mobileMoneyProviderID) === 27,
            mobileMoneyProviderID,
            mobileMoneyProviderName,
          },
        }));
      }

      setDeliveryMethod((prevState) => ({
        ...prevState,
        countryName,
        countryID,
        deliveryOption: serviceName,
        deliveryOptionId: serviceId,
        countryPhoneCode: phoneCode,
        beneficiaryType,
        relationshipID,
        relationShip,
      }));

      //autofill location details
      setLocationDetails((prevState) => ({
        ...prevState,
        state,
        city,
        address,
        street,
        addressLine,
        zipCode,
      }));

      // TODO: refactor this-> get exact date
      const formattedDate = new Date(documentExpiresOn);
      formattedDate.setUTCHours(23, 59, 59, 999);

      // Adding one day
      formattedDate.setDate(formattedDate.getDate() + 1);

      const result = formattedDate.toISOString().split("T")[0];

      //autofill personal info
      setPersonalInfo((prevState) => ({
        ...prevState,
        beneficiaryId: location.state.id,
        firstName,
        middleName,
        lastName,
        phoneNumberCode: phoneCode,
        phoneNumber: phoneNo,
        nickName,

        idNumber,
        // documentExpiresOn: formatDate(documentExpiresOn),
        // documentExpiresOn: new Date(documentExpiresOn)
        //   .toISOString()
        //   .split("T")[0],

        documentExpiresOn: result,

        businessDescription,
        businessRegistrationIssuedBy,
        typeOfBusiness,
        regionalBeneficiaryName,
        email,
      }));
    }

    setBeneficiaryData(location.state);
  }
};

export const yesterday = () => {
  const date = new Date();
  return date.setDate(date.getDate() - 1);
};
