import { ErrorMessage, Form, Formik } from "formik";
import React, { useContext, useState } from "react";
 import * as Yup from "yup";

import { toast } from "react-toastify";
import { checkPasswordStrength } from "../../../utils/PasswordStrength";
import { hasSequentialChars } from "../../../utils/validations/regex";
import { FormContext } from "../Registration";

const ValidationSchema = Yup.object().shape({
  password: Yup.string()
    .required("Password is required")
    .min(6, "Password must be at least 8 characters long")
    .matches(/[a-z]/, "Password must contain at least one lowercase letter")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    .matches(/\d/, "Password must contain at least one number")
    .matches(/[@$!%*?&#]/, "Password must contain at least one special character")
    .test("no-spaces", "Password must not contain spaces", (value) => !/\s/.test(value))
    .test("no-sequential", "Password should not contain sequential values (eg. 'qwerty', 'abc', '123')", (value) =>
      value ? !hasSequentialChars(value) : true
    ),

  //  Yup.string()
  //   .min(6, "Too Short!")
  //   .max(20, "Too Long!")
  //   .required("Required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm password is required"),
  //  Yup.string().min(6, "Too Short!").max(20, "Too Long!").required("Required"),
});

function Password() {
  const { formData, setFormData, activeStepIndex, setActiveStepIndex } = useContext(FormContext);
  const [password, setPassword] = useState("");
  const [confirmPassword, setconfirmPassword] = useState("");
  const [passwordStrength, setPasswordStrength] = useState("");
  const [uiBar, setUiBar] = useState("");
  const [hidePassword, setHidePassword] = useState(true);
  const [hideConfirmPassword, setHideConfirmPassword] = useState(true);

  const handleChangeInput = (val, setFieldValue) => {
    if (val.target.name === "password") {
      setPassword(val.target.value);
      setFieldValue(val.target.name, val.target.value, false);
      if (confirmPassword === val.target.value) {
        const strength = checkPasswordStrength(val.target.value);

        if (strength <= 30) {
          setPasswordStrength("weak");
          setUiBar("passwordRed");
        } else if (strength <= 50) {
          setPasswordStrength("strong");
          setUiBar("passwordAmber");
        } else {
          setPasswordStrength("very strong");
          setUiBar("passwordGreen");
        }
      }
    }
  };

  const setAndCheckConfirmPassword = (e, setFieldValue) => {
    setPasswordStrength("");
    setFieldValue(e.target.name, e.target.value);
    setconfirmPassword(e.target.value);
    if (password === e.target.value) {
      const strength = checkPasswordStrength(e.target.value);

      if (strength <= 30) {
        setPasswordStrength("weak");
        setUiBar("passwordRed");
      } else if (strength <= 50) {
        setPasswordStrength("strong");
        setUiBar("passwordAmber");
      } else {
        setPasswordStrength("very strong");
        setUiBar("passwordGreen");
      }
    }
  };

  const onSubmit = (values) => {
    const { password, confirmPassword } = values;
    const data = { ...formData, ...values };
    setFormData(data);
    const strength = checkPasswordStrength(password);

    if (strength <= 30) {
      toast.error("your password is to weak");
      return;
    } else {
      if (password === confirmPassword) {
        setActiveStepIndex(activeStepIndex + 1);
      } else {
        toast.error("Password does not match!");
      }
    }
  };

  return (
    <Formik
      initialValues={{
        password: formData.password,
        confirmPassword: formData.confirmPassword,
      }}
      validationSchema={ValidationSchema}
      onSubmit={(val) => onSubmit(val)}
    >
      {({ handleChange, handleBlur, handleSubmit, setFieldValue, errors, touched, values }) => (
        <Form id="registration-form" className="w-100 row justify-content-center " onSubmit={handleSubmit}>
          <div className="h3 text-center my-4 step-title">Create your password</div>

          <div className="input-password col-12 col-sm-8 col-lg-6 col-xl-4">
            <label>Your password</label>
            <input
              className={`form-control form-input  ${errors.password && touched.password ? "form-error" : ""}`}
              type={hidePassword ? "password" : "text"}
              name="password"
              onChange={(e) => handleChangeInput(e, setFieldValue)}
              onBlur={handleBlur}
              placeholder="Enter your password"
            />

            {hidePassword ? (
              <i
                className="fa fa-eye icon first-password-icon password-icon__orange"
                onClick={() => setHidePassword(false)}
              ></i>
            ) : (
              <i
                className="fa fa-eye-slash first-password-icon password-icon__blue"
                aria-hidden="true"
                onClick={() => setHidePassword(true)}
              ></i>
            )}
          </div>
          <div className="error-text col-12 text-center my-2">
            <ErrorMessage name="password" />
          </div>

          <div className="col-12 mb-2">
            <p className="password-rule">
              Alphanumeric, 6-20 characters with at least one of the following special characters @#$%^&+=][
            </p>
          </div>

          <div className="input-password col-12 col-sm-8 col-lg-6 col-xl-4">
            <input
              className={`form-control form-input ${
                errors.confirmPassword && touched.confirmPassword ? "form-error" : ""
              }`}
              type={hideConfirmPassword ? "password" : "text"}
              name="confirmPassword"
              onChange={(e) => setAndCheckConfirmPassword(e, setFieldValue)}
              onBlur={handleBlur}
              placeholder="Confirm Password"
            />
            {hideConfirmPassword ? (
              <i
                className="fa fa-eye icon confirm-password-icon password-icon__orange"
                onClick={() => setHideConfirmPassword(false)}
              ></i>
            ) : (
              <i
                className="fa fa-eye-slash confirm-password-icon password-icon__blue"
                aria-hidden="true"
                onClick={() => setHideConfirmPassword(true)}
              ></i>
            )}
          </div>
          <div className="error-text col-12 text-center my-2">
            <ErrorMessage name="confirmPassword" />
          </div>
          <div className={`col-12 col-sm-8 col-lg-6 col-xl-4 ${uiBar}`}></div>
          {passwordStrength ? <p>{passwordStrength}</p> : <></>}

          <div className="error-text col-12 text-center my-2">
            <ErrorMessage name="terms" />
          </div>
          <div className="col-12 col-sm-8 col-lg-6 col-xl-4">
            <button className="btn btn-orange w-100" type="submit">
              Next
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default Password;
